import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Privacy = () => {

  return (
    <>
      <div className='privacy-div'>
        <h1>Política de Privacidad</h1>
        <p><strong>Fecha de Entrada en Vigor:</strong> 2023-12-27</p>
        <p>E-comienza ("nosotros", "nuestro" o "la Empresa") se compromete a proteger la privacidad de la información personal de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, usamos y compartimos la información que recopilamos a través de los anuncios en Facebook e Instagram.</p>
        <h2>1. Información Recopilada</h2>
        <p>Recopilamos información que nos proporciona directamente, como su nombre, dirección de correo electrónico, número de teléfono y cualquier otra información que elija compartir con nosotros.</p>
        <p>Además, podemos recopilar automáticamente información sobre su interacción con nuestros anuncios, incluidos datos sobre visualizaciones, clics y conversiones.</p>
        <h2>2. Uso de la Información</h2>
        <p>Utilizamos la información recopilada para:</p>
        <ul>
          <li>Personalizar y mejorar su experiencia con nuestros anuncios.</li>
          <li>Enviarle información sobre nuestros productos y promociones.</li>
          <li>Analizar el rendimiento de nuestros anuncios y mejorar nuestras estrategias de marketing.</li>
        </ul>
        <h2>3. Compartir Información</h2>
        <p>No compartiremos su información personal con terceros sin su consentimiento, excepto cuando sea necesario para cumplir con las leyes aplicables.</p>
        <h2>4. Cookies y Tecnologías Similares</h2>
        <p>Utilizamos cookies y otras tecnologías similares para recopilar información sobre su actividad en nuestros anuncios y mejorar su experiencia.</p>
        <h2>5. Seguridad de la Información</h2>
        <p>Implementamos medidas de seguridad para proteger su información personal, pero no podemos garantizar la seguridad absoluta de la información transmitida a través de Internet.</p>
        <h2>6. Cambios en la Política de Privacidad</h2>
        <p>Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Le notificaremos sobre cambios significativos mediante un aviso en nuestro sitio web o por otros medios.</p>
        <h2>7. Sus Derechos y Opciones</h2>
        <p>Usted tiene derecho a acceder, corregir y eliminar su información personal. Póngase en contacto con nosotros a través de info@e-comienza.com para ejercer estos derechos.</p>
        <h2>8. Contacto</h2>
        <p>Si tiene preguntas o inquietudes sobre nuestra Política de Privacidad, comuníquese con nosotros a través de info@e-comienza.com.</p>
      </div>
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Privacy;