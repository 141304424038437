import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Image from '../../elements/Image';

const Banner = ({
  ...props
}) => {

  const [bannerOpen, setBannerOpen] = useState(true);
  const webWaUrl = 'https://web.whatsapp.com/send?phone=19544708414&text=Hola,%20quiero%20conocer%20más%20sobre%20e-comienza.';
  const mobileWaUrl = 'whatsapp://send?phone=19544708414&text=Hola,%20quiero%20conocer%20más%20sobre%20e-comienza.';
  //web.whatsapp
  //api.whatsapp
  function goToWhatsapp() {
    if (isMobile) {
      window.open(mobileWaUrl, '_blank');
    } else {
      window.open(webWaUrl, '_blank');
    }
  }

  return (
    <>
      {bannerOpen && (
        <div {...props} className="banner">
          <div className="banner-inner text-xxs" onClick={() => goToWhatsapp()}>
            <Image
                src={require('../../../assets/images/wa-logo.wine.svg')}
                alt="WhatsApp Logo"
                width={50}
                height={50}/>
            <div className='banner-container'><a className="banner-link banner-link-white" target="_blank" rel="noopener noreferrer">Ecríbenos</a></div>
            {/* <button className="banner-close" onClick={() => setBannerOpen(false)}>
            <Image
                  src={require('../../../assets/images/wa-logo.wine.svg')}
                  alt="WhatsApp Logo"
                  width={16}
                  height={16} />
            </button> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Banner;